<script lang="ts">
  import { onMount } from 'svelte';

  import Card, { Content } from '@smui/card';
  import Paper, { Content as PaperContent } from '@smui/paper';
  import { Icon } from '@smui/common';

  import { api } from '../../store';

  import Loading from '../Loading.svelte';

  export let importData: any;
  export let save: Task[];

  type Task = {
    title: string;
    type: string;
    steps: Step[];
  };

  type Step = {
    content: string;
    type: string;
    error?: string;
  };

  const testGuide: Task[] = [
    {
      title: 'Test',
      type: 'task',
      steps: [
        {
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rutrum sit amet augue tempor laoreet. Quisque laoreet mauris magna, eu volutpat justo finibus eget. Fusce dapibus ipsum ac faucibus facilisis. Etiam sed eros arcu. Maecenas sagittis neque ac nulla consectetur tortor.',
          type: 'step'
        }
      ]
    }
  ];

  let guide: Task[] = null;

  onMount(async () => {
    // Debug
    // save = testGuide;
    const body = new FormData();
    body.append('file', importData.upload[0]);
    const headers = new Headers();
    headers.append($api.key, $api.value);
    headers.append('template', '1'); //importData.template.id);
    const options = { method: 'POST', headers, body };
    const res = await fetch($api.server + '/convert', options);
    guide = await res.json();

    // if (!guide.find((t: Task) => t.steps.find((s: Step) => s.error)))
    save = guide;
  });
</script>

{#if !guide}
  <Loading />
{:else}
  <div class="card-container">
    {#each guide as task, i}
      <Card style="margin-bottom:65px" class="mdc-elevation--z4">
        <div style="padding: 1rem;">
          <h2 class="mdc-typography--headline6" style="margin: 0;">
            {#if task.type === 'completion'}
              Completion
            {:else}
              {i + 1}. {task.title}
            {/if}
          </h2>
        </div>
        <Content
          class="mdc-typography--body2"
          style="padding:0;background:#f7f8fa"
        >
          {#each task.steps as step, j}
            <div style="float:left; margin:10px">
              <Paper class={`step ${step.error ? 'error' : ''}`} elevation={0}>
                <PaperContent class="stepContent">
                  {#if step.type === 'completion'}
                    <div style="margin-bottom:10px;text-align:center">
                      <strong>Success!</strong><br />
                      <Icon class="material-icons" style="color:#00ffcc">
                        check_circle_outline
                      </Icon>
                    </div>
                  {/if}
                  {step.content}
                </PaperContent>
              </Paper>
              {#if step.type !== 'completion'}{j + 1}{/if}
              {#if step.error} - {step.error}{/if}
            </div>
          {/each}
        </Content>
      </Card>
    {/each}
  </div>
{/if}

<style lang="scss">
  :global(.step) {
    width: 186px;
    height: 120px;
    padding: 16px;
    overflow: hidden;
    background-color: #2c405b;
  }

  :global(.step.error) {
    background-color: #822;
  }
  :global(.stepContent) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    color: #fff;
    font-size: 12px !important;
    line-height: 1.25rem !important;
  }
</style>
