<script lang="ts">
  import Dialog, {
    Header,
    Title,
    Content,
    Actions,
    InitialFocus
  } from '@smui/dialog';
  import IconButton from '@smui/icon-button';
  import Button from '@smui/button';
  import CircularProgress from '@smui/circular-progress';

  import ModalUpload from './ModalUpload.svelte';
  import ModalEnvironment from './ModalEnvironment.svelte';
  import ModalTemplate from './ModalTemplate.svelte';
  import ModalVerify from './ModalVerify.svelte';
  import ModalGuides from './ModalGuides.svelte';
  import ModalImport from './ModalImport.svelte';
  import ModalComplete from './ModalComplete.svelte';

  import { modal } from '../../store';

  let slides: any[] = [
    {
      id: 'environment',
      title: 'Choose Your Environment',
      desc: 'Pick the environment below where you would like to create the Guide.',
      use: ModalEnvironment
    },
    {
      id: 'name',
      title: 'Name Your Guide',
      desc: 'Enter a name to create a new Guide.',
      use: ModalGuides
    },
    {
      id: 'template',
      title: 'Choose Template',
      desc: 'Please choose an import template that matches your data.',
      use: ModalTemplate
    },
    {
      id: 'upload',
      title: 'Upload File',
      desc: 'Please choose a file to upload.',
      use: ModalUpload
    },
    {
      id: 'convert',
      title: 'Verify Data',
      desc: 'Make sure that your data was imported correctly.',
      use: ModalVerify
    },
    {
      id: 'import',
      title: 'Import Data',
      desc: 'Please check your selections below before importing the Guide.',
      use: ModalImport
    },
    {
      id: 'complete',
      title: 'Complete',
      desc: '',
      use: ModalComplete
    }
  ];

  let slideIdx: number = 0;
  let importData = newImport();
  $: curSlide = slides[slideIdx];
  $: error = null;
  $: nextDisabled = !importData[slides[slideIdx].id];

  function back() {
    slideIdx--;
  }

  function next() {
    nextDisabled = true;
    if (slideIdx === slides.length - 1) $modal.open = false;
    else slideIdx++;
  }

  function newImport() {
    slideIdx = 0;
    return slides.reduce((a, v) => ({ ...a, [v.id]: null }), {});
  }

  function close() {
    importData = newImport();
    $modal.open = false;
  }
</script>

<Dialog
  open={$modal.open}
  fullscreen
  aria-labelledby="fullscreen-title"
  aria-describedby="fullscreen-content"
  scrimClickAction=""
  escapeKeyAction=""
  on:MDCDialog:closed={close}
  surface$style="width: 850px; max-width: calc(100vw - 32px);"
>
  <Header style="padding-bottom:20px">
    <Title id="fullscreen-title">
      <CircularProgress
        class="progress-circle"
        progress={(slideIdx + 1) / slides.length}
      />
      Import Guide
    </Title>
    <IconButton action="close" class="material-icons">close</IconButton>
  </Header>
  {#key curSlide}
    <Content id="fullscreen-content">
      <div class="mdc-typography--headline6">{curSlide.title}</div>
      <div class="mdc-typography--subtitle1" style="margin-bottom:20px">
        {curSlide.desc}
      </div>
      {#key $modal.open}
        <svelte:component
          this={curSlide.use}
          bind:importData
          bind:save={importData[curSlide.id]}
          bind:error
        />
      {/key}
    </Content>
    <Content class="footer">
      {#if slideIdx < slides.length - 1}
        {#if slideIdx}
          <Button variant="outlined" on:click={back}>Back</Button>
        {/if}
        <Button
          variant="raised"
          use={[InitialFocus]}
          disabled={nextDisabled}
          on:click={next}
        >
          Next
        </Button>
      {:else}
        <Button variant="raised" on:click={newImport}>New</Button>
        <Button variant="raised" on:click={close}>Close</Button>
      {/if}
    </Content>
  {/key}
  <Dialog
    open={error}
    slot="over"
    scrimClickAction=""
    escapeKeyAction=""
    on:MDCDialog:closed={() => (error = null)}
  >
    <Content>
      {@html error}
    </Content>
    <Actions>
      <Button defaultAction use={[InitialFocus]}>OK</Button>
    </Actions>
  </Dialog>
</Dialog>

<style lang="scss">
  :global(.progress-circle) {
    height: 32px;
    width: 32px;
    vertical-align: bottom;
  }
  :global(.footer) {
    min-height: 76px;
  }
  :global(.steps) {
    pointer-events: none;
    margin: 0;
  }
  :global(.steps .mdc-slider__track--active_fill) {
    border-color: transparent;
  }
</style>
