<script lang="ts">
  import Dialog, { Header, Title, Content, Actions } from '@smui/dialog';
  import Button from '@smui/button';
  import Textfield, { InputComponentDev } from '@smui/textfield';

  import Loading from '../Loading.svelte';

  import { api } from '../../store';

  interface ITextfieldProps {
    input: InputComponentDev;
    focused: boolean;
    dirty: boolean;
    invalid: boolean;
    value: string | null;
    length: number;
  }

  const key = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX';
  // const key = 'A1EA01E4-7F0B-4B51-BB69-B52DCFCAB23E';
  const inputArr = key
    .split('-')
    .map(
      (_) => ({ value: _, dirty: true, length: _.length } as ITextfieldProps)
    );

  let loading = true;
  let errorMsg = null;
  let open = true;
  $: formInvalid = !!inputArr.find((_) => !_.value || _.invalid || !_.dirty);
  $: if ($api && !$api.value) {
    if (localStorage.serial) validate(localStorage.serial);

    loading = false;
  }

  function submit() {
    validate(inputArr.map((_) => _.value).join('-'));
  }

  async function validate(serial) {
    const headers = new Headers();
    headers.append($api.key, serial);
    const options = {
      method: 'POST',
      headers
    };
    const res = await fetch($api.server + '/license', options);
    const json = await res.json();

    if (json.success) {
      $api.value = localStorage.serial = serial;
      open = false;
    } else
      throw new invalidSerial('Invalid Activation Code. Please try again.');
  }

  function invalidSerial(msg) {
    loading = false;
    errorMsg = msg;
  }
</script>

<Dialog
  bind:open
  fullscreen
  aria-labelledby="large-scroll-title"
  aria-describedby="large-scroll-content"
  scrimClickAction=""
  escapeKeyAction=""
  surface$style="width: 850px; max-width: calc(100vw - 32px);"
>
  {#if loading}
    <Content style="margin:100px auto;text-align:center">
      <Loading /><br />
      Loading Serial Key
      <p tabindex="0" />
    </Content>
  {:else}
    <Header style="margin-bottom:20px">
      <Title id="fullscreen-title">Activation</Title>
    </Header>
    <Content id="fullscreen-content">
      <div class="mdc-typography--headline6">Input Activation Code</div>
      <div class="mdc-typography--subtitle1" style="margin-bottom:20px">
        If you do not know your activation code, please contact your CraneMorley
        account representative.
      </div>
      <div>
        {#each inputArr as props, i}
          <Textfield
            bind:input={props.input}
            bind:value={props.value}
            bind:dirty={props.dirty}
            invalid={props.invalid}
            variant="filled"
            input$maxlength={props.length}
            input$minlength={props.length}
            label={Array(props.length + 1).join('X')}
            input$style={`font-family:Roboto Mono;width:${
              10 * props.length
            }px;text-transform:uppercase`}
            on:focus={() => {
              props.focused = props.dirty = true;
            }}
            on:blur={() => (props.focused = false)}
            on:input={(e) => {
              props.invalid =
                props.value && props.value.length !== props.length;
            }}
          />
          {#if i < key.split('-').length - 1}&nbsp;-&nbsp;{/if}
        {/each}
      </div>
    </Content>
    <Content class="footer">
      <Button variant="raised" on:click={submit} disabled={formInvalid}
        >Submit</Button
      >
    </Content>
  {/if}
  <Dialog bind:open={errorMsg} slot="over">
    <Content>
      {errorMsg}
    </Content>
    <Actions>
      <Button>OK</Button>
    </Actions>
  </Dialog>
</Dialog>

<style lang="scss">
  :global(.footer) {
    min-height: 76px;
  }
  :global(.steps) {
    pointer-events: none;
    margin: 0;
  }
  :global(.steps .mdc-slider__track--active_fill) {
    border-color: transparent;
  }
</style>
