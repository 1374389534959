<script lang="ts">
  import Card, { Content, PrimaryAction } from '@smui/card';
  import Paper, { Title, Subtitle, Content as PaperContent } from '@smui/paper';
  import List, {
    Item,
    Meta,
    Text,
    PrimaryText,
    SecondaryText,
    Separator
  } from '@smui/list';
  import Button, { Label, Icon } from '@smui/button';
  import LayoutGrid, { Cell } from '@smui/layout-grid';

  import type { ITemplate } from '../import-modal/ModalTemplate.svelte';
  import { msal, api, account, modal, isElectron } from '../../store';

  let templates: ITemplate[] = [];

  $: if ($api && $api.value) {
    (async () => {
      const json = await getTemplates();

      templates = json.map((_) => ({
        ..._,
        ext: _.path.split('.').pop(),
        file: _.path.split('/').pop(),
        icon: _.path.split('.').pop() + '.svg',
        disable: false
      }));
    })();
  }

  async function getTemplates() {
    const headers = new Headers();
    headers.append($api.key, $api.value);
    const options = { method: 'GET', headers };
    const res = await fetch($api.server + '/templates', options);
    return await res.json();
  }

  function download(fileUrl, fileName) {
    var a = document.createElement('a');
    a.href = fileUrl;
    a.setAttribute('download', fileName);
    a.click();
  }
</script>

{#if $account}
  <LayoutGrid>
    <Cell span={12}>
      <h1>Welcome</h1>
      <p>
        Welcome to the Guides Tool! If you already have a file ready for import,
        click the “Start Import” button below. Starting from scratch? No
        problem. Download a template to get started.
      </p>
    </Cell>
    <Cell span={6}>
      <Card>
        <PrimaryAction on:click={() => {}}>
          <div
            style="background:#ccc url('images/hololens.png') center center;background-size:cover"
          >
            <div style="margin-top:40%" />
          </div>
          <Content class="mdc-typography--body2">
            <h2 class="mdc-typography--headline6" style="margin: 0;">
              Start Import
            </h2>
            <h3
              class="mdc-typography--subtitle2"
              style="margin: 0 0 10px; color: #888;"
            >
              This is where you upload your prepared files.
            </h3>
            Begin here by clicking the "Start Import" button below.<br />
            <br />
            <Button
              variant="raised"
              style="width:100%"
              on:click={() => ($modal.open = true)}
            >
              <Icon class="material-icons">file_upload</Icon>
              <Label>Start Import</Label>
            </Button>
          </Content>
        </PrimaryAction>
      </Card>
    </Cell>
    <Cell span={6}>
      <Paper style="padding:24px">
        <Title>Download Templates</Title>
        <Subtitle>
          <small>
            These templates will help you get started with an import.
          </small>
        </Subtitle>
        <Separator style="margin:10px -24px 0 -24px" />
        <PaperContent style="margin:0 -24px">
          <List class="demo-list" twoLine avatarList singleSelection>
            {#each templates as item}
              <Item
                style="padding:0"
                on:click={() => download(item.path, item.file)}
                disabled={item.disabled}
              >
                <img class="icon" src={'images/' + item.icon} alt={item.icon} />
                <Text>
                  <PrimaryText>{item.name}</PrimaryText>
                  <SecondaryText>{item.ext}</SecondaryText>
                </Text>
                <Meta class="material-icons" style="margin-right:10px">
                  file_download
                </Meta>
              </Item>
            {/each}
          </List>
        </PaperContent>
      </Paper>
    </Cell>
  </LayoutGrid>
{:else}
  <div class="login-container">
    <Card class="login">
      <Content>
        <img src="logo.svg" alt="Logo" style="height:80px" />
        <h1>Welcome to the Guides Tool</h1>
        <p class="desc">
          Begin by signing into your Microsoft work or school account.
        </p>
        <div class="actions">
          <Button
            variant="raised"
            style="margin-bottom:10px;width:100%"
            on:click={$msal.login}
          >
            Sign in
          </Button>
          {#if !$isElectron}
            <Button
              variant="outlined"
              style="width:100%"
              on:click={() =>
                download('./__installerName__', '__installerName__')}
            >
              <Icon class="material-icons">file_download</Icon>
              <Label>Download Desktop App</Label>
            </Button>
          {/if}
        </div>
        <br />
        <!-- <Button>Learn more</Button> -->
      </Content>
    </Card>
  </div>
{/if}

<style lang="scss">
  h1 {
    line-height: 2rem;
  }
  .login-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .desc {
    margin-bottom: 70px;
    font-size: 14px;
  }
  .actions {
    display: inline-block;
    margin-bottom: 30px;
    width: 300px;
  }

  .icon {
    height: 100%;
  }
  * :global(.login) {
    width: 590px;
  }
  @media (max-width: 640px) {
    * :global(.login) {
      width: 400px;
    }
  }
</style>
