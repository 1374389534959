<script lang="ts">
  import Button, { Label, Icon } from '@smui/button';
  import HelperText from '@smui/textfield/helper-text/index';
  import Loading from '../Loading.svelte';

  export let importData: any;
  export let save: FileList | null;
  export let error;

  let uploadActive = false;
  let uploadFiletype = '';
  const filetypeExpected = importData.template.path.split('.').pop(); //'xlsx';

  let files;
  let unique = {};
  $: if (files && files) {
    uploadFiletype = files[0].name.split('.').pop();
    if (uploadFiletype === filetypeExpected) save = files;
    else {
      error = `Incorrect filetype of <strong>.${uploadFiletype}</strong> was selected. You need to
    select a file with the extension <strong>.${filetypeExpected}</strong>.`;
      unique = {};
      files = null;
    }
    uploadActive = false;
  }

  const filetypes = {
    xlsx: [
      'xlsx',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    docx: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    ]
  };

  function uploadClick() {
    // if (window.electron) upload(window.electron.uploadDialog());
    uploadActive = true;
    document.getElementById('file').click();
    document.body.onfocus = () => {
      uploadActive = false;
      // setTimeout(() => {
      //   uploadActive = !!files;
      //   document.body.onfocus = null;
      // }, 1500);
    };
  }
</script>

<!-- {#if !window.electron} -->
{#key unique}
  <input
    bind:files
    type="file"
    id="file"
    accept={filetypes[filetypeExpected].join(',')}
    on:click={(e) => {
      if (files) uploadActive;
    }}
  />
{/key}
<!-- {/if} -->
{#if uploadActive}
  <Loading />
{:else}
  <div>
    <Button variant="outlined" on:click={uploadClick}>
      <Icon class="material-icons">file_upload</Icon>
      <Label>Upload File</Label>
    </Button>
    {files ? files[0].name : ''}
    <HelperText style="opacity:1">.{filetypeExpected}</HelperText>
  </div>
{/if}

<style lang="scss">
  #file {
    width: 0;
    height: 0;
    z-index: -1;
    position: absolute;
    overflow: hidden;
    opacity: 0;
  }
</style>
