import { writable } from "svelte/store";
import { IPC_MESSAGES } from '../electron/Constants';
import AuthProvider from './auth/AuthProvider';
let auth;
export const account = writable(null);
export const api = writable(null);
export const msal = writable({
    electron: {
        init: function () {
            globalThis.api.on(IPC_MESSAGES.SHOW_WELCOME_MESSAGE, (data) => (account.set(data.account)));
            delete this.init;
            msal.set(this);
        },
        login: () => globalThis.api.send(IPC_MESSAGES.LOGIN),
        logout: () => globalThis.api.send(IPC_MESSAGES.LOGOUT),
        getInstance: async (scopes) => globalThis.api.getInstance(scopes),
        getInstances: async () => globalThis.api.getInstances(),
    },
    browser: {
        init: async function () {
            auth = new AuthProvider();
            const login = await auth.loginSilent();
            if (login)
                account.set(login);
            delete this.init;
            msal.set(this);
        },
        login: async () => {
            const login = await auth.login();
            if (login)
                account.set(login);
        },
        logout: () => auth.logout(),
        getInstance: async (scopes) => auth.getInstanceToken(scopes),
        getInstances: async () => auth.getInstancesToken(),
    },
    debug: {
        init: async function () {
            delete this.init;
            msal.set(this);
        },
        login: async () => {
            account.set({ account: { name: 'Debug', username: 'debug@email.com' } });
        },
        logout: () => null,
        getInstance: async () => null,
        getInstances: async () => [],
    }
});
export const modal = writable({ open: false });
export const isElectron = writable(null);
