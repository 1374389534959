import { PublicClientApplication, LogLevel, InteractionRequiredAuthError } from "@azure/msal-browser";
import * as APP_CONFIG from "../config/CM.json";
const MSAL_CONFIG = {
    auth: APP_CONFIG.authOptions,
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback(loglevel, message, containsPii) {
                console.log(message);
            },
            piiLoggingEnabled: false,
            logLevel: LogLevel.Error,
        }
    }
};
/**
 * AuthProvider for application - handles authentication in app.
 */
export default class AuthProvider {
    // private loginRedirectRequest?: RedirectRequest;
    // private loginRequest?: PopupRequest;
    constructor() {
        this.clientApplication = new PublicClientApplication(MSAL_CONFIG);
        this.account = null;
        this.setRequestObjects();
    }
    setRequestObjects() {
        const baseSilentRequest = {
            account: null,
            forceRefresh: false
        };
        // this.authCodeUrlParams = { ...APP_CONFIG.request.authCodeUrlParameters };
        this.authCodeRequest = Object.assign({}, APP_CONFIG.request.authCodeRequest);
        this.silentInstancesRequest = Object.assign(Object.assign({}, baseSilentRequest), { scopes: APP_CONFIG.resourceApi.scopes });
        this.silentInstanceRequest = Object.assign(Object.assign({}, baseSilentRequest), { scopes: null });
    }
    async getInstancesToken() {
        return await this.getToken(this.silentInstancesRequest);
    }
    async getInstanceToken(scopes) {
        return await this.getToken(Object.assign(Object.assign({}, this.silentInstanceRequest), { scopes }));
    }
    async getToken(request) {
        let authResponse;
        const account = this.account || await this.getAccount();
        if (account) {
            request.account = account;
            authResponse = await this.getTokenSilent(request);
        }
        else {
            const authCodeRequest = Object.assign(Object.assign({}, this.authCodeUrlParams), request);
            authResponse = await this.getTokenInteractive(authCodeRequest);
        }
        return authResponse.accessToken || null;
    }
    async getTokenSilent(tokenRequest) {
        try {
            return await this.clientApplication.acquireTokenSilent(tokenRequest);
        }
        catch (error) {
            return await this.getTokenInteractive(tokenRequest);
        }
    }
    async getTokenInteractive(tokenRequest) {
        try {
            return await this.clientApplication.acquireTokenPopup(tokenRequest);
        }
        catch (e) {
            console.error(e);
        }
    }
    async login() {
        const authResult = await this.clientApplication.loginPopup(this.loginRequest);
        return this.handleResponse(authResult);
    }
    async loginSilent() {
        if (!this.account)
            this.account = await this.getAccount();
        return this.account;
    }
    async logout() {
        let account;
        if (this.account)
            account = this.account;
        const req = {
            account,
            onRedirectNavigate: () => false
        };
        try {
            await this.clientApplication.logoutRedirect(req);
        }
        catch (e) {
            console.error(e);
        }
    }
    async getTokenRedirect(silentRequest, interactiveRequest) {
        try {
            const response = await this.clientApplication.acquireTokenSilent(silentRequest);
            return response.accessToken;
        }
        catch (e) {
            console.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                console.log("acquiring token using redirect");
                this.clientApplication.acquireTokenRedirect(interactiveRequest)
                    .catch(console.error);
            }
            else {
                console.error(e);
            }
        }
        return null;
    }
    async handleResponse(response) {
        if (response !== null)
            this.account = response.account;
        else
            this.account = await this.getAccount();
        return this.account;
    }
    async getAccount() {
        const currentAccounts = this.clientApplication.getAllAccounts();
        if (!currentAccounts.length) {
            console.log("No accounts detected");
            return undefined;
        }
        if (currentAccounts.length > 1) {
            console.log("Multiple accounts detected, need to add choose account code.");
            return currentAccounts[0];
        }
        return currentAccounts.length === 1 ? currentAccounts[0] : null;
    }
}
