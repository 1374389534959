<script lang="ts">
  import { Router, Route } from 'svelte-routing';

  import { AppContent } from '@smui/drawer';

  import Header from './components/layout/Header.svelte';

  import PageHome from './components/routes/PageHome.svelte';
  import PageUploads from './components/routes/PageUploads.svelte';
  import PageTemplates from './components/routes/PageTemplates.svelte';
  import PageGuides from './components/routes/PageGuides.svelte';
  import PageImport from './components/routes/PageImport.svelte';
  import PageExport from './components/routes/PageExport.svelte';
  import ImportModal from './components/import-modal/ImportModal.svelte';
  import RegModal from './components/reg-modal/RegModal.svelte';

  import { api, msal, account, isElectron } from './store';
  import * as APP_CONFIG from './config/CM.json';

  export let url = '';
  const production = false;
  const path = window.location.pathname;
  const basepath = /^\/?[a-zA-Z]+:/.test(path)
    ? path.substr(0, path.indexOf(':') + 1)
    : '/';

  $isElectron = !!globalThis.api;

  $api = {
    server: APP_CONFIG.server[APP_CONFIG.server.env],
    key: 'x-api-key',
    value: null
  };

  if ($isElectron) $msal.electron.init();
  else if (APP_CONFIG.debug) $msal.debug.init();
  else $msal.browser.init();

  console.log('Guides Tool v' + '__buildVersion__ __buildDate__');
  console.log('push test');
</script>

<svelte:head>
  {#if $isElectron}
    <meta
      http-equiv="Content-Security-Policy"
      content={`default-src 'self';
      connect-src ${$api.server};
      style-src ${APP_CONFIG.cors.style.join(' ')};
      font-src ${APP_CONFIG.cors.font.join(' ')};`}
    />
  {/if}
</svelte:head>

<main>
  <Router {url} {basepath}>
    <div class="top-app-bar-container flexor">
      <Header />
      <div class="flexor-content">
        <div class="drawer-container">
          <!-- <Sidebar /> -->
          <AppContent class="app-content">
            <main class="main-content">
              <Route path="" component={PageHome} />
              <Route path="uploads" component={PageUploads} />
              <Route path="templates" component={PageTemplates} />
              <Route path="guides" component={PageGuides} />
              <Route path="import" component={PageImport} />
              <Route path="export" component={PageExport} />
            </main>
          </AppContent>
        </div>
      </div>
    </div>
    <RegModal />
    {#if $api.value && $account}
      <ImportModal />
    {/if}
  </Router>
</main>

<style lang="scss">
  .top-app-bar-container {
    width: 100%;
    height: 100vh;
  }

  .flexor {
    display: inline-flex;
    flex-direction: column;
  }

  .flexor-content {
    flex-basis: 0;
    height: 0;
    flex-grow: 1;
    overflow: auto;
  }
  /* These classes are only needed because the
    drawer is in a container on the page. */
  .drawer-container {
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }

  * :global(.app-content) {
    flex: auto;
    overflow: auto;
    position: relative;
    flex-grow: 1;
  }

  .main-content {
    overflow: auto;
    padding: 16px;
    height: 100%;
    box-sizing: border-box;
    background-color: #f8f8f8;
  }
</style>
