<script lang="ts">
  import TopAppBar, { Row, Section, Title } from '@smui/top-app-bar';
  import Button, { Label, Icon } from '@smui/button';

  import AccountMenu from '../AccountMenu.svelte';
  import { account } from '../../store';

  let prominent = false;
  let dense = false;
  let secondaryColor = false;
</script>

<TopAppBar
  variant="static"
  {prominent}
  {dense}
  color={secondaryColor ? 'secondary' : 'primary'}
>
  <Row>
    <Section>
      <img src="logo.svg" alt="Logo" />
      <Title>Guides Tool</Title>
    </Section>    
    {#if $account}
      <Section align="end" toolbar>
        <Button href="./__installerName__" download="__installerName__">
          <Icon class="material-icons">file_download</Icon>
          <Label>Desktop App</Label>
        </Button>
        <AccountMenu />
      </Section>
    {/if}
    {#if !$account}
    <Section align="end" toolbar>
      <div>
        <a href="privacypolicy.html" target="_blank" class="mdc-button mdc-top-app-bar__action-item mdc-ripple-upgraded" style="--mdc-ripple-fg-size: 81px; --mdc-ripple-fg-scale: 1.85414; --mdc-ripple-fg-translate-start: 14.9844px, -22.5px; --mdc-ripple-fg-translate-end: 27.2422px, -22.5px;"><div class="mdc-button__ripple"></div><span class="mdc-button__label">Privacy Policy</span></a>
      </div>
    </Section>
    {/if}
  </Row>
</TopAppBar>

<style lang="scss">
  img {
    height: 100%;
  }
</style>
