<script lang="ts">
  import Textfield from '@smui/textfield';
  import HelperText from '@smui/textfield/helper-text/index';

  export let save: string;

  let invalid;
</script>

<div>
  <Textfield
    variant="outlined"
    bind:value={save}
    label="Guide Name"
    style="width:100%"
    helperLine$style="width: 100%;"
    required
    {invalid}
    on:input={() => (invalid = !save.length)}
  >
    <HelperText slot="helper">
      This is the name that will appear in Guides.
    </HelperText>
  </Textfield>
</div>
