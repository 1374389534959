<script lang="ts">
  import List, { Item, Text, PrimaryText, SecondaryText } from '@smui/list';

  import { api, msal } from '../../store';
  import Loading from '../Loading.svelte';

  export let save: Environment;

  type Environment = {
    name: string;
    url: string;
    scopes: string[];
  };

  $: selected = save ? save.name : null;
  $: ready = $api && $msal;

  async function getEnvironments(): Promise<Environment[]> {
    const token = await $msal.getInstances();
    const headers = new Headers();
    headers.append($api.key, $api.value);
    headers.append('Authorization', 'Bearer ' + token);
    const options = { method: 'GET', headers };
    const res = await fetch($api.server + '/environments', options);
    return await res.json();
  }
</script>

{#if ready}
  {#await getEnvironments()}
    <Loading />
  {:then items}
    <List twoLine style="margin:0 -24px">
      {#each items as item (item.name)}
        <Item
          on:SMUI:action={() => (save = item)}
          selected={selected === item.name}
          style="padding-left:26px"
        >
          <Text>
            <PrimaryText>{item.name}</PrimaryText>
            <SecondaryText>{item.url}</SecondaryText>
          </Text>
        </Item>
      {/each}
    </List>
  {:catch error}
    Retrieving environments failed!<br />
    {error}
  {/await}
{/if}
