<script lang="ts">
  import IconButton from '@smui/icon-button';

  import Menu, { MenuComponentDev } from '@smui/menu';
  import { Anchor } from '@smui/menu-surface';
  import List, {
    Item,
    Separator,
    Graphic,
    Text,
    PrimaryText,
    SecondaryText
  } from '@smui/list';

  import { account, msal } from '../store';

  let menu: MenuComponentDev;
  let anchor: HTMLDivElement;
  let anchorClasses: { [k: string]: boolean } = {};
</script>

{#if $account}
  <div
    class={Object.keys(anchorClasses).join(' ')}
    use:Anchor={{
      addClass: (className) => {
        if (!anchorClasses[className]) {
          anchorClasses[className] = true;
        }
      },
      removeClass: (className) => {
        if (anchorClasses[className]) {
          delete anchorClasses[className];
          anchorClasses = anchorClasses;
        }
      }
    }}
    bind:this={anchor}
  >
    <IconButton
      class="material-icons"
      on:click={() => menu.setOpen(true)}
      aria-label="Account">account_circle</IconButton
    >
    <Menu
      bind:this={menu}
      anchor={false}
      bind:anchorElement={anchor}
      anchorCorner="BOTTOM_LEFT"
    >
      <List>
        <Item disabled>
          <Text>
            <PrimaryText>{$account.name || ''}</PrimaryText>
            <SecondaryText>{$account.username || ''}</SecondaryText>
          </Text>
        </Item>
        <Separator />
        <!-- <Item on:SMUI:action={() => (clicked = "Copy")}>
          <Graphic class="material-icons">settings</Graphic>
          <Text>Settings</Text>
        </Item>
        <Separator /> -->
        <Item
          on:SMUI:action={() => {
            $msal.logout();
            $account = null;
          }}
        >
          <Graphic class="material-icons">logout</Graphic>
          <Text>Sign out</Text>
        </Item>
      </List>
    </Menu>
  </div>
{/if}
