<script lang="ts">
  import Tab, { Label as TabLabel } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import Button, { Label as ButtonLabel, Icon } from "@smui/button";
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
    SortValue,
  } from "@smui/data-table";
  import LinearProgress from "@smui/linear-progress";
  import IconButton from "@smui/icon-button";

  let active = "Recent";

  type Guides = {
    id: number;
    title: string;
    date: number;
    complete: boolean;
  };
  let items: Guides[] = [];
  let sort: keyof Guides = "id";
  let sortDirection: Lowercase<keyof typeof SortValue> = "ascending";

  if (typeof fetch !== "undefined") {
    fetch("https://jsonplaceholder.typicode.com/todos")
      .then((response) => response.json())
      .then((json) => (items = json.map((_, i) => ({ ..._, date: i }))));
  }

  function handleSort() {
    items.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === "ascending" ? "slice" : "reverse"
      ]();
      if (typeof aVal === "string" && typeof bVal === "string") {
        return aVal.localeCompare(bVal);
      }
      return Number(aVal) - Number(bVal);
    });
    items = items;
  }
</script>

<h1>Guides</h1>

<Button variant="raised">Open Guides</Button>
<Button variant="outlined">
  <Icon class="material-icons">upload_file</Icon>
  <ButtonLabel>Learn about guides</ButtonLabel>
</Button>

<TabBar tabs={["Recent", "All"]} let:tab bind:active>
  <Tab {tab} minWidth>
    <TabLabel>{tab}</TabLabel>
  </Tab>
</TabBar>

<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:MDCDataTable:sorted={handleSort}
  table$aria-label="Guides list"
  style="width: 100%;border:0"
>
  <Head>
    <Row>
      <Cell numeric columnId="id">
        <IconButton class="material-icons">arrow_upward</IconButton>
        <Label>ID</Label>
      </Cell>
      <Cell columnId="title" style="width: 100%;">
        <IconButton class="material-icons">arrow_upward</IconButton>
        <Label>Name</Label>
      </Cell>
      <Cell numeric columnId="date">
        <Label>Date Accessed</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell sortable={false}>Edit</Cell>
    </Row>
  </Head>

  <Body>
    {#each items as item (item.id)}
      <Row>
        <Cell numeric>{item.id}</Cell>
        <Cell class="title">{item.title}</Cell>
        <Cell>9/21/2021 2:24 PM</Cell>
        <Cell>Edit</Cell>
      </Row>
    {/each}
  </Body>

  <LinearProgress
    indeterminate
    aria-label="Data is being loaded..."
    slot="progress"
  />
</DataTable>

<style lang="scss">
  :global(.title) {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
