<script lang="ts" context="module">
  export type ITemplate = {
    id: number;
    name: string;
    ext: string;
    icon: string;
    file: string;
    path: string;
    disabled: boolean;
  };
</script>

<script lang="ts">
  import { onMount } from 'svelte';

  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
    SortValue
  } from '@smui/data-table';
  import Radio from '@smui/radio';
  import IconButton from '@smui/icon-button';

  import { api } from '../../store';
  import Loading from '../Loading.svelte';

  export let save: ITemplate;

  $: selected = save ? save.id : null;
  let items: ITemplate[] = [];
  let sort: keyof ITemplate = 'id';
  let sortDirection: Lowercase<keyof typeof SortValue> = 'ascending';

  onMount(async () => {
    const headers = new Headers();
    headers.append($api.key, $api.value);
    const options = { method: 'GET', headers };
    const res = await fetch($api.server + '/templates', options);
    items = await res.json();
  });

  function handleSort() {
    items.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === 'ascending' ? 'slice' : 'reverse'
      ]();
      return typeof aVal === 'string' && typeof bVal === 'string'
        ? aVal.localeCompare(bVal)
        : Number(aVal) - Number(bVal);
    });
    items = items;
  }
</script>

{#if !items.length}
  <Loading />
{:else}
  <DataTable
    sortable
    bind:sort
    bind:sortDirection
    on:MDCDataTable:sorted={handleSort}
    table$aria-label="Template list"
    style="width: 100%;border:0"
  >
    <Head>
      <Row>
        <Cell checkbox />
        <Cell numeric columnId="id">
          <IconButton class="material-icons">arrow_upward</IconButton>
          <Label>ID</Label>
        </Cell>
        <Cell columnId="name" style="width: 100%;">
          <Label>Name</Label>
          <IconButton class="material-icons">arrow_upward</IconButton>
        </Cell>
      </Row>
    </Head>

    <Body style="cursor:pointer">
      {#each items as item (item.id)}
        <Row on:click={() => (save = item)}>
          <Cell checkbox>
            <Radio bind:group={selected} value={item.id} touch />
          </Cell>
          <Cell numeric>{item.id}</Cell>
          <Cell class="name">{item.name}</Cell>
        </Row>
      {/each}
    </Body>
  </DataTable>
{/if}
