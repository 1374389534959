<script lang="ts">
  import Tab, { Label as TabLabel } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import Button, { Label as ButtonLabel, Icon } from '@smui/button';
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Label,
    SortValue
  } from '@smui/data-table';
  import LinearProgress from '@smui/linear-progress';
  import IconButton from '@smui/icon-button';

  import { api } from '../../store';

  let active = 'Recent';

  let valueTypeFiles: FileList | null = null;
  $: if (valueTypeFiles != null && valueTypeFiles.length) {
    upload(valueTypeFiles[0]);
  }

  type Guides = {
    id: number;
    title: string;
    date: number;
    complete: boolean;
  };
  let items: Guides[] = [];
  let sort: keyof Guides = 'id';
  let sortDirection: Lowercase<keyof typeof SortValue> = 'ascending';

  if (typeof fetch !== 'undefined') {
    fetch('https://jsonplaceholder.typicode.com/todos')
      .then((res) => res.json())
      .then((json) => (items = json.map((_, i) => ({ ..._, date: i }))));
  }

  function uploadClick() {
    // if (window.electron) upload(window.electron.fileDialog());
    // else
    document.getElementById('file').click();
  }

  function upload(file) {
    console.log(file);
    if (file) {
      var headers = new Headers();
      headers.append($api.key, $api.value);
      const formData = new FormData();
      formData.append('file', file);

      fetch($api.server + '/upload', {
        method: 'POST',
        headers,
        body: formData
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
        });
    }
    // console.log(window.eAPI.upload());
  }

  function handleSort() {
    items.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === 'ascending' ? 'slice' : 'reverse'
      ]();
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      return Number(aVal) - Number(bVal);
    });
    items = items;
  }
</script>

<h1>Files</h1>
{#if !window.api}
  <input bind:files={valueTypeFiles} type="file" id="file" />
  <!-- <label for="file" id="browseLabel">Upload New File</label> -->
{/if}
<Button variant="raised" on:click={() => uploadClick()}>Upload New File</Button>
<Button variant="outlined">
  <Icon class="material-icons">upload_file</Icon>
  <ButtonLabel>Open Folder</ButtonLabel>
</Button>

<TabBar tabs={['Recent', 'All']} let:tab bind:active>
  <Tab {tab} minWidth>
    <TabLabel>{tab}</TabLabel>
  </Tab>
</TabBar>

<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:MDCDataTable:sorted={handleSort}
  table$aria-label="Guides list"
  style="width: 100%;border:0"
>
  <Head>
    <Row>
      <Cell numeric columnId="id">
        <IconButton class="material-icons">arrow_upward</IconButton>
        <Label>ID</Label>
      </Cell>
      <Cell columnId="title" style="width: 100%;">
        <IconButton class="material-icons">arrow_upward</IconButton>
        <Label>Name</Label>
      </Cell>
      <Cell numeric columnId="date">
        <Label>Date Accessed</Label>
        <IconButton class="material-icons">arrow_upward</IconButton>
      </Cell>
      <Cell sortable={false}>Edit</Cell>
    </Row>
  </Head>

  <Body>
    {#each items as item (item.id)}
      <Row>
        <Cell numeric>{item.id}</Cell>
        <Cell class="title">{item.title}</Cell>
        <Cell>9/21/2021 2:24 PM</Cell>
        <Cell>Edit</Cell>
      </Row>
    {/each}
  </Body>

  {#if !items.length}
    <LinearProgress
      indeterminate
      aria-label="Data is being loaded..."
      slot="progress"
    />
  {/if}
</DataTable>

<style>
  #file {
    width: 0;
    height: 0;
    z-index: -1;
    position: absolute;
    overflow: hidden;
    opacity: 0;
  }
  :global(.title) {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
