<script lang="ts">
  import { onMount } from 'svelte';

  import Paper, { Title, Content } from '@smui/paper';

  import Loading from '../Loading.svelte';

  import { api, account, msal } from '../../store';

  export let save;
  export let importData;

  onMount(() => {
    importGuide();
  });

  async function importGuide() {
    const body = JSON.stringify({
      name: importData.name,
      environment: importData.environment,
      guide: importData.convert,
      email: $account.username
    });
    const token = await $msal.getInstance(importData.environment.scopes);
    save = null;
    const headers = new Headers();
    headers.append($api.key, $api.value);
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('content-type', 'application/json');
    const options = { method: 'POST', headers, body };
    const res = await fetch($api.server + '/import', options);
    save = await res.json();
  }
</script>

{#if !save}
  <Loading />
{:else}
  <Paper color={save.success ? 'success' : 'error'} elevation={4}>
    <Title>
      Import of "{importData.name}" {save.success
        ? 'was Successful'
        : 'Failed'}!
    </Title>
    <Content>
      {#if save.success}
        Feel free to click the "New" button below to import another file.
      {:else}
        Please try again by clicking <a
          href="import"
          style="color:#fff"
          on:click|preventDefault={importGuide}>here</a
        >.
      {/if}
      Otherwise click the "Close" button to return to the home menu.
    </Content>
  </Paper>
{/if}

<style lang="scss">
  :global(.smui-paper--color-success) {
    color: #fff;
    background-color: #81c784;
  }
  :global(.smui-paper--color-error) {
    color: #fff;
    background-color: #e57373;
  }
</style>
