<script lang="ts">
  import Paper, { Title, Content } from '@smui/paper';

  export const save = 'complete';
  export let importData;
</script>

<Paper color="primary" elevation={4}>
  <Title>{importData.name}</Title>
  <Content>
    <p>
      <strong>{importData.environment.name}</strong>
      {importData.environment.url}
    </p>
    <p>
      <strong>*Total Tasks:</strong>
      {importData.convert.length}<br />
      <strong>*Total Steps:</strong>
      {importData.convert.reduce((p, v) => p + v.steps.length, 0)}
    </p>
    <p>
      <small><em>* Includes Completion Task and Step.</em></small>
    </p>
  </Content>
</Paper>
